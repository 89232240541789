import { useSelector } from "react-redux"

const FormLoader = () => {
	const formLoader = useSelector((state) => state.loaderReducer.formLoader)
	return (
		<>
			{formLoader ? (
				<div id="preloader">
					<div className="preloader-overlay">
						<figure className="preloader">
							<div className="dot white" />
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
						</figure>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}

export default FormLoader
